import { GetPayrollDto, GetPayrollSummaryDto } from "@api/api";
import { LabelValue } from "@shared/models/label-value.interface";
import { useCallback, useRef, useState } from "react";
import { TableExportField } from "../../../core/model/table-export-field.interface";
import { useReactToPrint } from "react-to-print";

export const useFormDropdownPayroll = () => {
  const [payrollExport, setPayrollExport] = useState([] as TableExportField[]);
  const [selectedPayslip, setSelectedPayslip] =
    useState<TableExportField | null>();
  const payslipRef = useRef(null);

  const handlePrintPayslip = useReactToPrint({
    content: () => payslipRef.current,
  });

  const getSum = useCallback((amount: number[]) => {
    const totalFees = amount.reduce((prev, current) => prev + current, 0);
    return totalFees;
  }, []);

  const updatePayrollExportById = (userId: string, updatedProperties: any) => {
    const updatedPayrollExport = payrollExport.map((payroll) =>
      payroll.userId === userId
        ? ({ ...payroll, ...updatedProperties } as TableExportField)
        : payroll
    );

    setPayrollExport(updatedPayrollExport);
  };

  const handleDeduction = useCallback(
    (
      paymentData: TableExportField,
      deductions: LabelValue<number>[],
      type: "government" | "extra"
    ) => {
      const deductionAmount = deductions.map((x) => x.value);
      const totalApplicableDeduction = getSum(deductionAmount);
      const currentPayrollUser = payrollExport.filter(
        (x) => x.userId === paymentData.userId
      )?.[0];

      if (type === "government") {
        const otherFees = currentPayrollUser?.totalOtherPaymentDeduction || 0;
        const grandDeduction =
          paymentData.grossDeduction + totalApplicableDeduction + otherFees;
        const grandSalary = paymentData.grossSalary - grandDeduction;

        updatePayrollExportById(paymentData.userId, {
          totalGovernmentDeduction: totalApplicableDeduction,
          govermentDeductionParticulars: deductions,
          netDeduction: grandDeduction,
          netSalary: grandSalary,
        });
      } else if (type === "extra") {
        const govFees = currentPayrollUser?.totalGovernmentDeduction || 0;
        const grandDeduction =
          paymentData.grossDeduction + totalApplicableDeduction + govFees;
        const grandSalary = paymentData.grossSalary - grandDeduction;

        updatePayrollExportById(paymentData.userId, {
          totalOtherPaymentDeduction: totalApplicableDeduction,
          otherPaymentDeductionParticulars: deductions,
          netDeduction: grandDeduction,
          netSalary: grandSalary,
        });
      }
    },
    [payrollExport]
  );

  const getSummaryFields = useCallback((paymentData: GetPayrollSummaryDto) => {
    const workDays = paymentData?.no_of_days;
    const dailySalary = paymentData?.daily_salary || 0;

    const grossSalary = paymentData?.gross_salary_with_addendum;

    const absents = paymentData?.absents || 0;
    const breaks = paymentData?.breaks || 0;
    const lates = paymentData?.lates || 0;
    const overtimes = paymentData?.overtimes || 0;
    const undertimes = paymentData?.undertimes || 0;
    const leaves = paymentData?.leaves || 0;

    const absentPenalty = paymentData?.deduction_absent || 0;
    const incidentPenalty = paymentData?.deduction_incident || 0;
    const latePenalty = paymentData?.deduction_late || 0;
    const breakPenalty = paymentData?.deduction_break || 0;
    const overagePenalty = paymentData?.deduction_overage || 0;
    const penaltyPenalty = paymentData?.deduction_penalty || 0;
    const paymentPenalty = paymentData?.deduction_payment || 0;
    const undertimePenalty = paymentData?.deduction_undertime || 0;
    const grossDeduction =
      absentPenalty +
      incidentPenalty +
      latePenalty +
      breakPenalty +
      overagePenalty +
      paymentPenalty +
      penaltyPenalty +
      undertimePenalty;

    const bonusHoliday = paymentData?.bonus_holiday || 0;
    const incentives = paymentData?.bonus_incentive || 0;
    const otPay = paymentData?.overtime_pay || 0;
    const otMinutes = paymentData?.overtime_minutes || 0;

    return {
      workDays,
      dailySalary,
      grossSalary,
      bonusHoliday,
      incentives,

      absents,
      breaks,
      lates,
      overtimes,
      undertimes,
      leaves,

      absentPenalty,
      incidentPenalty,
      latePenalty,
      breakPenalty,
      overagePenalty,
      paymentPenalty,
      penaltyPenalty,
      undertimePenalty,
      grossDeduction,

      otPay,
      otMinutes,
    };
  }, []);

  const calculateSummation = useCallback((payrollField: GetPayrollDto[]) => {
    const genericInfo = payrollField[0];

    const summarizedFields = payrollField.reduce(
      (current, item) => {
        current.absents += item.is_absent ? 1 : 0;
        current.breaks +=
          parseInt(item.break_hours as unknown as string, 10) ?? 0;
        current.lates += item.late ?? 0;
        current.overtimes += item.ot_minutes ?? 0;
        current.undertimes += item.undertime ?? 0;
        current.leaves += item.is_onleave ? 1 : 0;

        current.absentPenalty += item.is_absent ? item.daily_rate : 0;
        current.incidentPenalty += (item as any).deduction_incident ?? 0;
        current.latePenalty += item.deduction_late;
        current.breakPenalty += item.deduction_break;
        current.overagePenalty += item.deduction_overage;
        current.loanPayment += (item as any).deduction_payment ?? 0;
        current.penaltyPenalty += item.deduction_penalty;
        current.undertimePenalty += item.deduction_undertime;

        current.bonusHoliday += item.bonus_holiday;
        current.incentives += item.bonus_incentive;
        current.otPay += item.ot_pay;
        current.otMinutes += item.ot_minutes ?? 0;

        return current;
      },
      {
        grossSalary: 0,
        absents: 0,
        breaks: 0,
        lates: 0,
        overtimes: 0,
        undertimes: 0,
        leaves: 0,

        absentPenalty: 0,
        incidentPenalty: 0,
        latePenalty: 0,
        breakPenalty: 0,
        overagePenalty: 0,
        loanPayment: 0,
        penaltyPenalty: 0,
        undertimePenalty: 0,

        bonusHoliday: 0,
        incentives: 0,
        otPay: 0,
        otMinutes: 0,
      } as TableExportField
    );

    const totalOutOfOffice = summarizedFields.absents + summarizedFields.leaves;
    const {
      absentPenalty,
      incidentPenalty,
      latePenalty,
      breakPenalty,
      overagePenalty,
      loanPayment: paymentPenalty,
      penaltyPenalty,
      undertimePenalty,
      bonusHoliday,
      incentives,
      otPay,
    } = summarizedFields;
    const grossDeduction =
      absentPenalty +
      incidentPenalty +
      latePenalty +
      breakPenalty +
      overagePenalty +
      paymentPenalty +
      penaltyPenalty +
      undertimePenalty;

    const workDays = payrollField.length - totalOutOfOffice;
    const dailySalary = genericInfo.daily_salary;
    const grossSalary = dailySalary * workDays;
    const totalBonus = bonusHoliday + incentives + otPay;
    const defaultNetSalary = grossSalary + totalBonus - grossDeduction;

    const updatedSummarizedFields = {
      ...summarizedFields,
      name: `${genericInfo.person_first_name} ${genericInfo.person_last_name}`,
      position: genericInfo.job_title_name,
      branch: genericInfo.branch_name,
      userId: genericInfo.user_code,
      workDays,
      dailySalary,
      grossSalary,

      sss: genericInfo.contribution_sss,
      philhealth: genericInfo.contribution_philhealth,
      pagibig: genericInfo.contribution_pagibig,

      grossDeduction,
      totalGovernmentDeduction: 0,
      totalOtherPaymentDeduction: 0,
      netDeduction: grossDeduction,
      netSalary: defaultNetSalary,
    } as TableExportField;

    return updatedSummarizedFields;
  }, []);

  return {
    payrollExport,
    selectedPayslip,
    payslipRef,
    calculateSummation,
    setSelectedPayslip,
    handlePrintPayslip,
    setPayrollExport,
    handleDeduction,
    getSummaryFields,
    getSum,
  };
};
