import { useNavigate } from "react-router-dom";
import { appFeature } from "../../../app.feature";
import { getVisibleMenu } from "@core/utility/menu-visibility";

export function useEditPersonMenu() {
  const navigate = useNavigate();
  const menu = [
    {
      label: "Employment",
      icon: "pi pi-file",
      items: [
        {
          label: "General",
          value: "general",
          payload: "",
          command: () => navigate(""),
        },
        {
          label: "Attachment",
          value: "attachment",
          payload: "attachment",
          command: () => navigate("attachment"),
        },
        {
          label: "Salary",
          value: "salary",
          payload: "salary",
          command: () => navigate("salary"),
        },
        {
          label: "Government Fees",
          value: "government",
          payload: "government",
          command: () => navigate("government"),
        },
      ],
    },
    {
      label: "Schedule",
      icon: "pi pi-file",
      items: [
        {
          label: "Shift",
          value: "shift",
          payload: "shift",
          command: () => navigate("shift"),
        },
        {
          label: "DTR",
          value: "daily-time-record",
          payload: "daily-time-record",
          command: () => navigate("daily-time-record"),
        },
        {
          label: "Requests",
          value: "employee-request",
          payload: "employee-request",
          command: () => navigate("request"),
        },
        {
          label: "Leave Ledger",
          value: "ledger",
          payload: "ledger",
          command: () => navigate("ledger"),
        },
        {
          label: "Branch Transfer",
          value: "transfer",
          payload: "transfer",
          command: () => navigate("transfer"),
        },
      ],
    },
    {
      label: "Behaviour",
      icon: "pi pi-file",
      items: [
        {
          label: "Score",
          value: "score",
          payload: "score",
          command: () => navigate("score"),
          data: {
            isHidden: !appFeature.features.employee.item.score,
          },
        },
        {
          label: "Disciplinary",
          value: "disciplinary",
          payload: "disciplinary",
          command: () => navigate("disciplinary"),
          data: {
            isHidden: !appFeature.features.employee.item.disciplinary,
          },
        },
      ],
      data: {
        isHidden: !appFeature.features.employee.item.behaviour,
      },
    },
    {
      label: "Monetary",
      icon: "pi pi-file",
      items: [
        {
          label: "Loan",
          value: "loan",
          payload: "loan",
          command: () => navigate("loan"),
        },
        {
          label: "Loan Payment",
          value: "payment",
          payload: "payment",
          command: () => navigate("payment"),
        },
        {
          label: "Incentives",
          value: "incentive",
          payload: "incentive",
          command: () => navigate("incentive"),
        },
        {
          label: "Overage",
          value: "overage",
          payload: "overage",
          command: () => navigate("overage"),
        },
        {
          label: "Incident",
          value: "employee-incident",
          payload: "employee-incident",
          command: () => navigate("incident"),
        },
        {
          label: "Incident Deduction",
          value: "employee-deduction",
          payload: "employee-deduction",
          command: () => navigate("deduction"),
        },
        {
          label: "Penalty",
          value: "penalty",
          payload: "penalty",
          command: () => navigate("penalty"),
        },
      ],
      data: {
        isHidden: !appFeature.features.employee.item.monetary,
      },
    },
    {
      label: "Others",
      icon: "pi pi-file",
      items: [
        {
          label: "Timeline",
          value: "timeline",
          payload: "timeline",
          command: () => navigate("timeline"),
        },
      ],
    },
  ];

  const filteredMenu = getVisibleMenu(menu);

  return { menu: filteredMenu };
}
