import "./quick-view-event.scss";
import TextBlock from "@shared/ui/text-block/text-block";
import { useDateTimeContext } from "@shared/utility/date-time-service/date-time.context";
import { SyntheticEvent, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button } from "primereact/button";
import NewEmployeeRequest, {
  NewEmployeeRequestDefaultFormProps,
} from "@domain/employee-request/new-employee-request/new-employee-request";
import { FormDropdownShiftProvider } from "@domain/shift/form-dropdown-shift/form-dropdown-shift.context";
import { FormDropdownStatusProvider } from "@domain/status/form-dropdown-status/form-dropdown-status.context";
import { Avatar } from "primereact/avatar";
import NewEmployeeScore, {
  EmployeeScoreDefaultProps,
} from "@domain/employee-score/new-employee-score/new-employee-score";
import { useQyGetPersonById } from "@core/query/person.query";
import { GetResponsePersonModel } from "@core/model/query-model-person";
import { Accordion, AccordionTab } from "primereact/accordion";
import DotLabel from "@shared/ui/dot-label/dot-label";
import NewEmployeeBranch from "@domain/employee-branch/new-employee-branch/new-employee-branch";
import { useFormApiMapper } from "@core/services/form-api-mapper.hook";
import { useNotificationContext } from "@shared/ui/notification/notification.context";
import { ConfirmDialog, confirmDialog } from "primereact/confirmdialog";
import { useQyDeleteEmployeeShift } from "@core/query/employee-shift.query";
import { useCalendarEvent } from "../calendar-helper.hook";

export interface QuickViewEventDataProps {
  name?: string;
  branchId?: string;
  userId?: string;
  bucketId?: string;
  eventType?: string;
  startShift?: string;
  endShift?: string;
  shiftCode?: string;
  shiftName?: string;
}
export interface QuickViewEventProps {
  eventData: QuickViewEventDataProps;
  onClose: () => void;
}

export function QuickViewEvent({ eventData, onClose }: QuickViewEventProps) {
  const { calendarGetShiftStyle } = useCalendarEvent();
  const { formatDateTime } = useDateTimeContext();
  const navigate = useNavigate();
  const { mapDeleteEmployeeShift } = useFormApiMapper();
  const { showError, showSuccess } = useNotificationContext();
  const [employeeScoreProps, setEmployeeScoreProps] =
    useState<EmployeeScoreDefaultProps>({
      personId: eventData.userId,
      branchId: eventData.branchId,
      bucketCode: eventData.bucketId,
      jobTitle: "",
    });
  const employeeRequestProps = {
    bucketCode: eventData?.bucketId,
    userCode: eventData?.userId,
    effectivityDateIsDisabled: true,
  } as NewEmployeeRequestDefaultFormProps;

  // API DELETE EmployeeShift
  const handleDeleteApiSuccess = () => {
    onClose();
    showSuccess("Employee Shift deleted successfully. Refresh Calendar");
  };
  const { mutate: deleteEmployeeShift } = useQyDeleteEmployeeShift(
    handleDeleteApiSuccess
  );

  // API GET PERSON BY ID
  const handleGetPersonSuccess = (response: GetResponsePersonModel) => {
    const jobId = response.data?.[0]?.bucket.job_title;
    setEmployeeScoreProps((items) => ({ ...items, jobTitle: jobId }));
  };
  useQyGetPersonById(eventData.userId || "", handleGetPersonSuccess, undefined);

  // LOCAL FUNCTIONS
  const handleView = (e: SyntheticEvent) => {
    e.preventDefault();
    navigate(
      `/employee/${eventData?.branchId}/${eventData?.userId}?return=event`
    );
  };

  const accept = () => {
    const shiftCode = eventData?.shiftCode;
    if (!shiftCode) {
      throw new Error("No employeeShift data found");
    }

    const form = mapDeleteEmployeeShift(shiftCode);
    deleteEmployeeShift(form);
  };
  const handleDeleteSchedule = () => {
    confirmDialog({
      message: "Are you sure you want to delete this shift?",
      header: "Delete Confirmation",
      icon: "pi pi-info-circle",
      defaultFocus: "reject",
      acceptClassName: "p-button-danger",
      accept,
    });
  };
  console.log({ employeeRequestProps });

  return (
    <div id="QuickViewEvent" className="quick-view-event">
      <ConfirmDialog />
      <div className="p-4">
        <section className="flex justify-between items-center">
          <section className="flex gap-2 items-center">
            <Avatar
              label={eventData?.name?.[0] || "-"}
              size="large"
              style={{ backgroundColor: "#2196F3", color: "#ffffff" }}
              shape="circle"
            />
            <h2>{eventData?.name}</h2>
          </section>
          <section className="flex gap-2">
            <Button label="View" onClick={handleView} outlined size="small" />
            {eventData?.shiftCode ? (
              <Button
                label="Delete Schedule"
                severity="danger"
                outlined
                onClick={handleDeleteSchedule}
              />
            ) : null}
          </section>
        </section>
        {eventData.eventType || eventData?.startShift || eventData?.endShift ? (
          <section className="mt-4 bg-gray-100 p-4 rounded-md flex justify-between">
            <div>
              <small className="block mb-4">Current Shift Schedule</small>
              <div className="flex-col justify-between">
                <DotLabel
                  label={eventData.eventType || "N/A"}
                  classBg={
                    calendarGetShiftStyle(eventData.eventType).classColor
                  }
                />
                {eventData?.shiftName ? (
                  <small>{eventData?.shiftName}</small>
                ) : null}
                <TextBlock
                  label="Start"
                  value={formatDateTime(eventData?.startShift) || "-"}
                />
                <TextBlock
                  label="End"
                  value={formatDateTime(eventData?.endShift) || "-"}
                />
              </div>
            </div>
          </section>
        ) : null}
      </div>

      <Accordion>
        <AccordionTab header="Employee Request">
          <FormDropdownShiftProvider>
            <FormDropdownStatusProvider>
              <NewEmployeeRequest
                standalone={true}
                defaultFormProps={employeeRequestProps}
                onSuccess={() => onClose()}
                defaultDate={eventData?.startShift}
              />
            </FormDropdownStatusProvider>
          </FormDropdownShiftProvider>
        </AccordionTab>
        <AccordionTab header="New Employee Score">
          <NewEmployeeScore
            standalone={true}
            defaultProps={employeeScoreProps}
          />
        </AccordionTab>
        <AccordionTab header="Temporary Transfer">
          <section className="flex flex-col gap-4">
            <NewEmployeeBranch
              standalone={true}
              defaultFormProps={{
                userCode: eventData?.userId,
                bucketCode: eventData?.bucketId,
              }}
            />
          </section>
        </AccordionTab>
      </Accordion>
    </div>
  );
}

export default QuickViewEvent;
