import { useNotificationContext } from "@shared/ui/notification/notification.context";
import "./login.scss";
import { useNavigate } from "react-router-dom";
import { useCallback, useEffect, useState } from "react";
import InputControl, {
  InputControlType,
} from "@shared/ui/hook-form/input-control/input-control";
import { generateCaptcha } from "@shared/utility/generate-captcha";
import StorageService from "@shared/services/storage.service";
import { AUTH, PROFILE } from "@core/utility/settings";
import { useQyLogin } from "@core/query/auth.query";
import { FieldErrors, useForm } from "react-hook-form";
import { LoginFormSchema, LoginRule } from "@core/form/form.rule";
import { loginFormDefault } from "@core/form/form.default";
import { zodResolver } from "@hookform/resolvers/zod";
import { getFormErrorMessage } from "@core/utility/get-error-message";
import { Button } from "primereact/button";
import { LocalAuth } from "@core/model/local-auth";
import { ApiToFormService } from "@core/services/api-to-form.service";
import { useFormApiMapper } from "@core/services/form-api-mapper.hook";
import { TokenDto } from "@api/api";
import BackgroundSlider from "react-background-slider";
import { appFeature } from "../../app.feature";
import { appConfig } from "../../app.config";

export function Login() {
  const { showError, showWarning } = useNotificationContext();
  const { mapLogin } = useFormApiMapper();
  const navigate = useNavigate();
  const logo = `${appConfig.assetPath}/icon-512x512.png?${new Date().toISOString()}`;
  const bukoLogo = "/buko-logo.png";
  const loginbg = `${appConfig.assetPath}/login-bg-1.jpg?${new Date().toISOString()}`;
  const loginbg2 = `${appConfig.assetPath}/login-bg-2.jpg?${new Date().toISOString()}`;
  const [passwordType, setPasswordType] =
    useState<InputControlType>("password");
  const [captcha, setCaptcha] = useState<string>(generateCaptcha(6));
  const buildVersion = process.env.REACT_APP_BUILD_VERSION;
  const semanticVersion = process.env.REACT_APP_SEMANTIC_VERSION;
  const apiurl = process.env.REACT_APP_API_URL;

  useEffect(() => {
    StorageService.clear(PROFILE);
    StorageService.clear(AUTH);
  }, []);

  // LOGIN API
  const handleLoginSuccess = (response: TokenDto) => {
    const decodedToken = parseJwt(response.access_token || "") as any;

    if (
      decodedToken?.roles?.name === "Owner" ||
      decodedToken?.roles?.name === "Branch Admin" ||
      decodedToken?.roles?.name === "Admin"
    ) {
      const mappedData = ApiToFormService.MapLocalAuth(response);
      StorageService.save<LocalAuth>(AUTH, mappedData);
      navigate("/");
      return;
    }

    showWarning("Sorry, only administrators are allowed to access the system");
  };
  const { mutate: loginUser } = useQyLogin(handleLoginSuccess);

  // FORM
  const parseJwt = (token: string) => {
    var base64Url = token.split(".")[1];
    var base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
    var jsonPayload = decodeURIComponent(
      window
        .atob(base64)
        .split("")
        .map(function (c) {
          return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
        })
        .join("")
    );

    return JSON.parse(jsonPayload) as string;
  };
  const formMethod = useForm<LoginFormSchema>({
    defaultValues: loginFormDefault,
    resolver: zodResolver(LoginRule),
  });
  const { control, reset, handleSubmit, setValue } = formMethod;
  const handleValidate = useCallback(
    (form: LoginFormSchema) => {
      const formData = mapLogin(form);
      loginUser(formData);
    },
    [loginUser, captcha, showWarning]
  );
  const handleValidateError = useCallback(
    (err: FieldErrors<LoginFormSchema>) => {
      const formMessage = getFormErrorMessage(err);
      showError(formMessage);
    },
    [showError]
  );
  const handleBukoLogo = () => {
    window.open("https://bukolabs.io/", "_blank", "noreferrer");
  };
  const handleKeyDown = (event: React.KeyboardEvent) => {
    if (event.key === "Enter") {
      event.preventDefault();
      handleSubmit(handleValidate, handleValidateError)();
    }
  };
  const handleRightIconAction = () => {
    const inputType = passwordType === "password" ? "text" : "password";
    setPasswordType(inputType);
  };
  const handleClock = () => {
    navigate("../clock");
  };

  return (
    <div id="Login" className="login">
      <BackgroundSlider
        images={[loginbg, loginbg2]}
        duration={10}
        transition={2}
      />
      <section className="flex flex-col h-screen justify-center items-center">
        <div className="p-16 bg-white opacity-95 rounded-lg">
          <div className="flex flex-col w-[400px]">
            <div className="flex flex-col items-center">
              <img
                src={logo}
                className="w-[220px]"
                alt={`${appFeature.company}`}
              />
            </div>
            <h1 className="m-0 mt-4">Login</h1>
            <span className="text-gray-600">
              Welcome to {appFeature.company} HRIS
            </span>
            <InputControl<LoginFormSchema>
              control={control}
              name="username"
              label="Username"
              className="w-full"
              onKeyDown={handleKeyDown}
            />
            <InputControl<LoginFormSchema>
              control={control}
              name="password"
              label="Password"
              className="w-full"
              type={passwordType}
              iconRight="pi-eye"
              iconRightAction={handleRightIconAction}
              onKeyDown={handleKeyDown}
            />

            <div className="flex flex-col w-full mt-5 gap-2">
              <Button
                label="Login"
                onClick={handleSubmit(handleValidate, handleValidateError)}
              />
              <Button label="Clock & Request" outlined onClick={handleClock} />
            </div>
          </div>
          <div
            className="flex flex-col w-[400px] items-center"
            onClick={handleBukoLogo}
          >
            <p className="m-0 font-bold">Powered by:</p>
            <img
              src={bukoLogo}
              className="w-[250px] cursor-pointer"
              alt="buko-labs"
            />
          </div>
          <span className="text-center fixed bottom-0 right-0 text-gray-300">
            build-{buildVersion}-{semanticVersion}-{apiurl}
          </span>
        </div>
      </section>
    </div>
  );
}

export default Login;
