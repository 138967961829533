import "./new-employee-request.scss";

import { useNavigate } from "react-router-dom";
import { zodResolver } from "@hookform/resolvers/zod";
import { FieldErrors, FormProvider, useForm } from "react-hook-form";
import { FaChevronLeft } from "react-icons/fa6";
import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";

import { useFormApiMapper } from "@core/services/form-api-mapper.hook";
import {
  useQyAmendmentEmployeeRequest,
  useQyBreakAmendmentEmployeeRequest,
  useQyChangeShiftEmployeeRequest,
  useQyLeaveEmployeeRequest,
  useQyOvertimeEmployeeRequest,
} from "@core/query/employee-request.query";
import {
  EmployeeRequestFormRule,
  EmployeeRequestFormSchema,
} from "@core/form/form.rule";
import { employeeRequestFormDefault } from "@core/form/form.default";
import { getFormErrorMessage } from "@core/utility/get-error-message";
import { useNotificationContext } from "@shared/ui/notification/notification.context";
import HeaderContent from "@shared/ui/header-content/header-content";
import { useState } from "react";
import { useFormDropdownRequestType } from "@domain/request-type/form-dropdown-request-type/form-dropdown-request-type.hook";
import { DesignatedRequestType } from "@domain/request-type/form-dropdown-request-type/designated-request-type.enum";
import FormEmployeeRequestOvertime from "../form-employee-request-overtime/form-employee-request-overtime";
import FormEmployeeRequestLeave from "../form-employee-request-leave/form-employee-request-leave";
import FormEmployeeRequestDtr from "../form-employee-request-dtr/form-employee-request-dtr";
import { useFormDropdownPersonContext } from "@domain/person/form-dropdown-person/form-dropdown-person.context";
import FormEmployeeRequestCsr from "../form-employee-request-csr/form-employee-request-csr";
import FormEmployeeRequestBreak from "../form-employee-request-break/form-employee-request-break";
import { useDateTimeContext } from "@shared/utility/date-time-service/date-time.context";

export interface NewEmployeeRequestDefaultFormProps {
  userCode: string;
  bucketCode: string;
  effectivityDateIsDisabled?: boolean;
  onSuccess: () => void;
}
export interface NewEmployeeRequestProps {
  standalone?: boolean; // If TRUE then it is not used in app route
  defaultFormProps?: NewEmployeeRequestDefaultFormProps;
  defaultDate?: string;
  onSuccess?: () => void;
}
export function NewEmployeeRequest({
  standalone,
  defaultFormProps,
  defaultDate,
  onSuccess,
}: NewEmployeeRequestProps) {
  // LOCAL DECLARATION
  const { formatDate } = useDateTimeContext();
  const { getDesignationMapping, getDesignatedRequestTypeEnumValue } =
    useFormDropdownRequestType();
  const { selectedPersonData } = useFormDropdownPersonContext();
  const navigate = useNavigate();
  const {
    mapOvertimeEmployeeRequest,
    mapLeaveEmployeeRequest,
    mapDtrEmployeeRequest,
    mapChangeShiftEmployeeRequest,
    mapBreakEmployeeRequest,
  } = useFormApiMapper();
  const { showError, showSuccess } = useNotificationContext();

  const designatedRequestType = getDesignationMapping();
  const [selectedRequestType, setSelectedRequestType] = useState("");
  const [selectedDisplayRequestType, setSelectedDisplayRequestType] =
    useState("");

  // QUERIES
  const handleApiSuccess = () => {
    showSuccess(`New Employee Request is created`);
    if (standalone && onSuccess) {
      onSuccess();
    } else {
      navigate(`../`);
    }
  };
  const { mutate: dtrRequest, isLoading: isLoadingDtr } =
    useQyAmendmentEmployeeRequest(handleApiSuccess);
  const { mutate: overtimeRequest, isLoading: isLoadingOt } =
    useQyOvertimeEmployeeRequest(handleApiSuccess);
  const { mutate: leaveRequest, isLoading: isLoadingLeave } =
    useQyLeaveEmployeeRequest(handleApiSuccess);
  const { mutate: changeShiftRequest, isLoading: isLoadingCsr } =
    useQyChangeShiftEmployeeRequest(handleApiSuccess);
  const { mutate: breakRequest, isLoading: isLoadingBreak } =
    useQyBreakAmendmentEmployeeRequest(handleApiSuccess);

  // LOCAL FUNCTIONS
  const formMethod = useForm<EmployeeRequestFormSchema>({
    defaultValues: {
      ...employeeRequestFormDefault,
      effectivityDate: defaultDate ? formatDate(defaultDate) : "",
    },
    resolver: zodResolver(EmployeeRequestFormRule),
  });
  const { handleSubmit } = formMethod;

  const handleValidate = (form: EmployeeRequestFormSchema) => {
    const defaultFormData = {
      userCode: defaultFormProps?.userCode,
      bucketCode: defaultFormProps?.bucketCode,
    };

    if (!standalone) {
      defaultFormData.userCode = selectedPersonData?.code;
      defaultFormData.bucketCode = selectedPersonData?.bucket.code;
    }

    const mutatedForm = {
      ...form,
      userCode: defaultFormData.userCode,
      bucketCode: defaultFormData.bucketCode,
      requestType: selectedRequestType,
    } as EmployeeRequestFormSchema;

    switch (selectedDisplayRequestType) {
      case DesignatedRequestType.Overtime:
        const overtimePayload = mapOvertimeEmployeeRequest(mutatedForm);
        overtimeRequest(overtimePayload);
        break;

      case DesignatedRequestType.DtrAmendment:
        const dtrAmendPayload = mapDtrEmployeeRequest(mutatedForm);
        dtrRequest(dtrAmendPayload);
        break;

      case DesignatedRequestType.Leave:
        const leavePayload = mapLeaveEmployeeRequest(mutatedForm);
        leaveRequest(leavePayload);
        break;

      case DesignatedRequestType.ChangeShift:
        const changeShiftPayload = mapChangeShiftEmployeeRequest(mutatedForm);
        changeShiftRequest(changeShiftPayload);
        break;

      case DesignatedRequestType.BreakAmendment:
        const breakAmendPayload = mapBreakEmployeeRequest(mutatedForm);
        breakRequest(breakAmendPayload);
        break;
    }
  };
  const handleValidateError = (err: FieldErrors<EmployeeRequestFormSchema>) => {
    const formMessage = getFormErrorMessage(err);
    showError(formMessage);
  };
  const handleRequestType = (selected: string) => {
    setSelectedRequestType(selected);
    const enumValue = getDesignatedRequestTypeEnumValue(
      designatedRequestType,
      selected
    );
    if (!enumValue) return;
    setSelectedDisplayRequestType(enumValue);
  };

  // LOCAL RENDER
  const displayForm = () => {
    switch (selectedDisplayRequestType) {
      case DesignatedRequestType.Overtime:
        return <FormEmployeeRequestOvertime />;
      case DesignatedRequestType.Leave:
        return <FormEmployeeRequestLeave />;
      case DesignatedRequestType.DtrAmendment:
        return (
          <FormEmployeeRequestDtr
            bucketCode={defaultFormProps?.bucketCode || ""}
          />
        );
      case DesignatedRequestType.ChangeShift:
        return (
          <FormEmployeeRequestCsr
            effectivityDateIsDisabled={
              defaultFormProps?.effectivityDateIsDisabled
            }
          />
        );
      case DesignatedRequestType.BreakAmendment:
        return <FormEmployeeRequestBreak />;

      default:
        return null;
    }
  };

  return (
    <div id="EmployeeRequest" className="employee-request">
      <HeaderContent
        title="New Employee Request"
        backIcon={standalone ? null : <FaChevronLeft />}
        onBack={() => navigate("../")}
        headingType={standalone ? "h3" : "h1"}
      >
        <Button
          className="w-full block"
          label="Save"
          disabled={
            isLoadingDtr || isLoadingCsr || isLoadingLeave || isLoadingOt
          }
          onClick={handleSubmit(handleValidate, handleValidateError)}
        ></Button>
      </HeaderContent>

      <div className="p-7">
        <FormProvider {...formMethod}>
          <Dropdown
            value={selectedRequestType}
            onChange={(e) => handleRequestType(e.value)}
            options={designatedRequestType}
            optionLabel="label"
            placeholder="Select request type"
            className="w-full md:w-14rem"
          />
          {displayForm()}
        </FormProvider>
      </div>
    </div>
  );
}

export default NewEmployeeRequest;
