import feature7s from "../clients/7s/feature.json";
import featureOtg from "../clients/otg/feature.json";
import { appConfig } from "./app.config";

export const appFeatureRecord = {
  ["7s"]: feature7s,
  ["otg"]: featureOtg,
};
export const appFeature =
  appFeatureRecord[appConfig.client as keyof typeof appFeatureRecord];
