import { appConfig } from "../../app.config";

export const SETTINGS = {
  dateFormat: "yyyy-MM-dd",
  dateTimeFormat: "yyyy-MM-dd p",
  staleTime: 20000, // 20 minutes
  minTimeForRenew: 250, // minimum time passed for the app to renew token
  maxTimeForTimeout: -180, // 3min - max time passed for the app to kick out user
  imageUrl: `http://152.42.202.1/${appConfig.client}-api/api/v1/cloud/storage/asset`,
};
export const AUTH = "auth";
export const PROFILE = "profile";
export const DEVICETOKEN = "devicetoken";
