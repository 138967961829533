import { Outlet, useNavigate } from "react-router-dom";
import "./setting.scss";
import HeaderContent from "@shared/ui/header-content/header-content";
import { Menubar } from "primereact/menubar";
import { useUserIdentityContext } from "@core/services/user-identity.context";
import { useCallback } from "react";
import { appFeature } from "../../app.feature";
import { getVisibleMenu } from "@core/utility/menu-visibility";

export function Setting() {
  const navigate = useNavigate();
  const { userIsOwner } = useUserIdentityContext();

  const role = {
    label: "Role",
    value: "role",
    payload: "role",
    command: () => navigate("role"),
  };

  const getEmploymentItems = useCallback(() => {
    const employmentItems = [
      {
        label: "Department",
        value: "department",
        payload: "",
        command: () => navigate(""),
      },
      {
        label: "Employment Type",
        value: "employment-type",
        payload: "employment-type",
        command: () => navigate("employment-type"),
      },
      {
        label: "Position",
        value: "job-title",
        payload: "job-title",
        command: () => navigate("job-title"),
      },
      {
        label: "Job Grade",
        value: "job-grade",
        payload: "job-grade",
        command: () => navigate("job-grade"),
      },
      {
        label: "Salary Type",
        value: "salary-type",
        payload: "salary-type",
        command: () => navigate("salary-type"),
      },
    ];

    if (userIsOwner()) {
      employmentItems.splice(1, 0, role);
    }

    return employmentItems;
  }, [userIsOwner]);

  const panelMenuItems = [
    {
      label: "Employment",
      icon: "pi pi-file",
      items: getEmploymentItems(),
    },
    {
      label: "Schedule",
      icon: "pi pi-file",
      items: [
        {
          label: "Shift",
          value: "shift",
          payload: "shift",
          command: () => navigate("shift"),
        },
        {
          label: "Shift Type",
          value: "shift-type",
          payload: "shift-type",
          command: () => navigate("shift-type"),
        },
      ],
    },
    {
      label: "Holiday",
      icon: "pi pi-file",
      items: [
        {
          label: "Holiday",
          value: "holiday",
          payload: "holiday",
          command: () => navigate("holiday"),
        },
        {
          label: "Holiday Type",
          value: "holiday-type",
          payload: "holiday-type",
          command: () => navigate("holiday-type"),
        },
        {
          label: "Request Type",
          value: "request-type",
          payload: "request-type",
          command: () => navigate("request-type"),
        },
        {
          label: "Leave Type",
          value: "leave-type",
          payload: "leave-type",
          command: () => navigate("leave-type"),
        },
        {
          label: "Leave Category",
          value: "leave-category",
          payload: "leave-category",
          command: () => navigate("leave-category"),
        },
        {
          label: "Leave Subcategory",
          value: "leave-subcategory",
          payload: "leave-subcategory",
          command: () => navigate("leave-subcategory"),
        },
      ],
    },
    {
      label: "Score",
      icon: "pi pi-file",
      items: [
        {
          label: "Questions",
          value: "questions",
          payload: "questions",
          command: () => navigate("questions"),
        },
      ],
      data: {
        isHidden: !appFeature.features.setting.item.score,
      },
    },
    {
      label: "Incidents",
      icon: "pi pi-file",
      items: [
        {
          label: "Incident Type",
          value: "incident-type",
          payload: "incident-type",
          command: () => navigate("incident-type"),
          data: {
            isHidden: !appFeature.features.setting.item.incidentType,
          },
        },
        {
          label: "Violation",
          value: "violation",
          payload: "violation",
          command: () => navigate("violation"),
          data: {
            isHidden: !appFeature.features.setting.item.violation,
          },
        },
        {
          label: "Infraction",
          value: "infraction",
          payload: "infraction",
          command: () => navigate("infraction"),
          data: {
            isHidden: !appFeature.features.setting.item.infraction,
          },
        },
        {
          label: "Penalty Schedule",
          value: "penalty-schedule",
          payload: "penalty-schedule",
          command: () => navigate("penalty-schedule"),
          data: {
            isHidden: !appFeature.features.setting.item.penaltySchedule,
          },
        },
      ],
      data: { isHidden: !appFeature.features.setting.item.incidents },
    },
    {
      label: "Others",
      icon: "pi pi-file",
      items: [
        {
          label: "Payment Method",
          value: "payment-method",
          payload: "payment-method",
          command: () => navigate("payment-method"),
          data: {
            isHidden: !appFeature.features.setting.item.paymentMethod,
          },
        },
        {
          label: "Utility Status",
          value: "utility-status",
          payload: "utility-status",
          command: () => navigate("utility-status"),
        },
        {
          label: "Attachment Type",
          value: "attachment-type",
          payload: "attachment-type",
          command: () => navigate("attachment-type"),
        },
        {
          label: "Device Authorization",
          value: "device-authorization",
          payload: "device-authorization",
          command: () => navigate("device-authorization"),
          data: {
            isHidden: !appFeature.features.setting.item.deviceAuthorization,
          },
        },
        {
          label: "Grouping",
          value: "grouping",
          payload: "grouping",
          command: () => navigate("grouping"),
          data: { isHidden: !appFeature.features.setting.item.grouping },
        },
      ],
    },
  ];

  const filteredMenu = getVisibleMenu(panelMenuItems);

  return (
    <div id="Setting" className="setting">
      <HeaderContent title="Settings Menu"></HeaderContent>

      <div className="p-7 ">
        <Menubar model={filteredMenu} />
        <section className="w-full">
          <Outlet />
        </section>
      </div>
    </div>
  );
}

export default Setting;
